<template>
  <div id="projects-vertical">
    <!-- banner -->
    <div class="banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>公益项目</div>
        <div>PUBLIC WELFARE PROJECTS</div>
      </div>
      <SubNav :navList="navList" />
    </div>
    <!-- content -->
    <div id="home-vertical">
      <!-- <div class="introduction">
        <div>
          人口老龄化已经成为我国严峻的社会问题，根据全国老龄办发布的数据，2000年至2018年间，我国60岁及以上老年人口从1.26亿人增加到2.49亿人，老年人口占总人口的比重从10.2%上升至17.9%，而且未来一段时间，老龄化程度将持续加深，无论在城市还是农村，老年人口都是持续增长，对养老服务的需求也是日渐迫切。
        </div>
        <div>
          随着社会发展，老人同时面临“留守”、“空巢”等问题，尤其是在一些贫困山区，生活物资匮乏，这些老人大多依靠国家兜底，仅保障基本生存，养老体系非常脆弱，老人逐渐丧失劳作能力和自理能力，长期不与人沟通，老人出现疾病缠身、沟通障碍、孤立淡漠等不同方面、不同程度的问题。农村养老服务体系建设成为迫切的社会需求，由政府、市场、公益、家庭联合性的解决方案是社会空白点。
        </div>
      </div> -->
      <div class="projects-wrapper">
        <div
          class="project clearfix"
          v-for="(item, index) in data"
          :class="{ odd: index % 2 !== 0 }"
          :key="index"
        >
          <div class="photo-box">
            <div class="project-name">
              <div>{{ item.name }}</div>
              <div>{{ item.enName }}</div>
              <img class="logo-bg" src="@/assets/images/logo-bg.png" alt="" />
            </div>
            <div class="operation">
              <router-link
                class="item"
                :to="{ name: 'ProjectDetails', params: { id: item.id } }"
              >
                <div class="icon"></div>
                <span>项目详情</span>
              </router-link>
              <a class="item" :href="item.donationUrl"
                ><div class="icon"></div>
                <span>我要捐赠</span></a
              >
            </div>
            <div class="photo">
              <img :src="item.img" alt="" />
            </div>
          </div>
          <div class="brief-box">
            <div>项目简介</div>
            <div>{{ item.introduction }}</div>
          </div>
          <div class="outcome-box" v-if="item.outcome.length !== 0">
            <div>项目成果</div>
            <div class="data-list">
              <div
                class="item"
                v-for="(oItem, oIndex) in item.outcome"
                :key="oIndex"
              >
                <div class="count">{{ oItem.count.toLocaleString() }}</div>
                <div class="unit">{{ oItem.unit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToTop />
  </div>
</template>

<script>
import ToTop from "@/components/ToTop.vue";
import SubNav from "@/components/SubNav";
import { queryPage } from "@/api/frontArticle";

export default {
  name: "HomeVertical",
  components: {
    ToTop,
    SubNav
  },
  data() {
    return {
      navList: [
        {
          name: "公益项目",
          englishName: "",
          path: "/projects/home-vertical",
          component: "HomeVertical"
        }
        // {
        //   name: "项目故事",
        //   englishName: "PROJECT STORY",
        //   path: "/news/story",
        //   component: "Story"
        // }
      ],
      projects: [
        {
          id: 1,
          name: "乡村助老员支持计划",
          enName: "",
          img: require("@/assets/images/news1/news2-9.jpg"),
          introduction:
            "项目于2016年5月开始，经过6年时间，历经西南、西北、重庆、广东四个试点区域的分区迭代，由长益基金会协同政府部门、长江商学院多位学子、社会各界爱心人士共创。",
          outcome: [
            // {
            //   count: 2157,
            //   unit: "受益老人数（户）"
            // },
            // {
            //   count: 62,
            //   unit: "受益村社区（个）"
            // },
            // {
            //   count: 3199,
            //   unit: "受益老人数（位）"
            // },
            // {
            //   count: 200000,
            //   unit: "养老服务（人次）"
            // }
          ]
        },
        {
          id: 2,
          name: "小红花梦想",
          enName: "",
          img: require("@/assets/images/dream.jpeg"),
          introduction:
            "“小红花梦想·爷爷奶奶陪伴计划”由腾讯公益慈善基金会发起，与上海市长益公益基金会共创，针对乡村孤寡老人日常照顾缺位、情感缺失的问题，计划在一年时间内，为云南、陕西、重庆等地的2000名乡村孤寡老人通过乡村助老员为其提供养老服务和物资支持，使老人生活质量和精神面貌得到改善，提升老人获得感和幸福感。并用互联网思维、以小红花做为广大捐助者的链接，增强短视频反馈和传播，从公众筹资角度助力可持续发展。",
          outcome: []
        },
        {
          id: 3,
          name: "用心聆听·为爱奔跑",
          enName: "",
          img: require("@/assets/images/running.jpeg"),
          introduction:
            "2022年5月上海疫情期间，由长江商学院高远户外俱乐部和上海校友会联合主办的“用心聆听·为爱奔跑”—心理健康复原建设520线上公益马拉松，报名共计1318人，其中长江老师、校友1074人，37所商学院、戈友组织的嘉宾244人。同时，长江商学院高远户外俱乐部携手上海校友会、久爱公益将520线上公益马拉松在运动超级APP-悦跑圈上线，以线上赛的方式号召全社会爱心跑者共同参与，活动报名人数超过10万人。",
          outcome: []
        },
        {
          id: 4,
          name: "科技助老",
          enName: "",
          img: require("@/assets/images/technology.jpeg"),
          introduction:
            "在“十四五”期间，为积极助力应对人口老龄化，解决养老服务的痛点、难点问题。在重庆市委市政府、中国老龄事业发展基金会指导下，聚焦重庆市加快建设高质量养老服务体系的需要，充分发挥腾讯公司在持续社会价值和科技创新等领域的优势，上海市长益公益基金会充分运用在乡村人才振兴、基层社会治理的经验和能量传递的公益方法，运用腾讯SSV/腾讯银发科技实验室技术能力，围绕机构、社区和居家养老三大场景，落地重庆智慧养老“银发守护”计划的整体探索方案。进一步推动建设重庆智慧型养老服务体系，加快探索适应重庆养老事业发展的新模式、新方法，促进持续社会价值、共同富裕等方面的深度融合和创新发展。",
          outcome: []
        }
      ],
      data: [],
      page: 1,
      pageSize: 10,
      dataCount: 0,
      pageCount: 0,
      search: {
        type: 15
      }
    };
  },
  methods: {
    async handleQuery(page = this.page, pageSize = this.pageSize) {
      this.page = page;
      this.pageSize = pageSize;
      try {
        const res = await queryPage({
          page,
          pageSize,
          param: {
            ...this.search
          }
        });
        this.data = res.data.map(o => {
          return {
            ...o,
            name: o.title,
            enName: "",
            img: o.coverImg,
            introduction: o.profile,
            outcome: []
          };
        });
        this.page = res.page;
        this.pageSize = res.pageSize;
        this.dataCount = res.dataCount;
        this.pageCount = res.pageCount;
      } catch (e) {
        this.data = [];
        this.page = 1;
        this.pageSize = 10;
        this.dataCount = 0;
        this.pageCount = 0;
      }
    }
  },
  mounted() {
    this.handleQuery();
  }
};
</script>

<style lang="scss" scoped>
#projects-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.banner {
  background-image: url(../../assets/images/two-banner.jpeg);
  background-position: 50% 35%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 80vw;
  min-width: 1240px;
  max-width: 100%;
  height: 23vw;
  min-height: 340px;
  max-height: 430px;
  background-size: cover;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(37, 163, 246, 0.267);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      font-size: 40px;
      margin-bottom: 30px;
    }
    div:last-child {
      font-size: 20px;
    }
  }
}
#home-vertical {
  width: 1240px;
  margin: 0 auto;
  padding: 0 30px;
}
.introduction {
  padding: 0 0 0 60px;
  line-height: 30px;
  // text-indent: 2em;
  margin-bottom: 140px;
  div {
    margin-bottom: 30px;
  }
}
// .projects-wrapper {
// position: relative;
// display: flex;
// }
.project {
  margin-bottom: 0px;
  position: relative;
  .photo-box {
    height: 730px;
    position: relative;
    .project-name {
      position: absolute;
      top: 0;
      // left: 0;
      width: 470px;
      height: 380px;
      background-color: #d7eafb;
      padding: 60px;
      overflow: hidden;
      div:first-child {
        font-size: 30px;
        color: #004898;
        font-weight: 600;
        margin-bottom: 20px;
      }
      div:nth-child(2) {
        font-size: 12px;
        color: #999;
      }
      .logo-bg {
        width: 250px;
        position: absolute;
        left: -80px;
        bottom: -80px;
      }
    }
    .operation {
      position: absolute;
      top: 50px;
      right: 0;
      width: 380px;
      height: 52px;
      display: flex;
      background-color: #d7eafb;
      color: #004898;
      a {
        text-decoration: none;
      }
      .item {
        cursor: pointer;
        flex: 1;
        // line-height: 52px;
        // text-align: center;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 20px;
          height: 20px;
        }
      }
      .item:first-child {
        .icon {
          background-image: url("../../assets/images/xm-icon-7.png");
        }
      }
      .item:first-child:hover {
        .icon {
          background-image: url("../../assets/images/xm-icon-7-hover.png");
        }
      }
      .item:last-child {
        .icon {
          background-image: url("../../assets/images/xm-icon-6.png");
        }
      }
      .item:last-child:hover {
        .icon {
          background-image: url("../../assets/images/xm-icon-6-hover.png");
        }
      }
      .item:hover {
        color: #fff;
        background-color: #004898;
        a {
          color: #fff;
        }
      }
    }
    .photo {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 950px;
      height: 600px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .brief-box {
    float: right;
    width: 950px;
    min-height: 230px;
    display: flex;
    padding: 60px 10px 60px 0;
    line-height: 30px;
    // border-bottom: 1px solid #ccc;
    div:first-child {
      flex: 0 0 200px;
      height: 100%;
      font-size: 24px;
    }
  }
  .outcome-box {
    float: right;
    width: 950px;
    height: 220px;
    display: flex;
    padding: 60px 10px 60px 0;
    line-height: 30px;
    border-top: 1px solid #ccc;
    > div:first-child {
      flex: 0 0 200px;
      height: 100%;
      font-size: 24px;
    }
    .data-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .count {
          font-family: "Impact";
          font-size: 48px;
          color: #004898;
          margin-bottom: 24px;
        }
        .unit {
          font-size: 14px;
        }
      }
    }
  }
}

// 奇数
.project.odd {
  .photo-box {
    .project-name {
      right: 0;
      .logo-bg {
        width: 250px;
        position: absolute;
        left: 300px;
        bottom: -80px;
      }
    }
    .operation {
      left: 0;
    }
    .photo {
      left: 0;
    }
  }
  .brief-box {
    float: left;
  }
  .outcome-box {
    float: left;
  }
}
</style>
